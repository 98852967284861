(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@radbse/auth"), require("moment"), require("reselect"), require("@radbse/hooks"), require("@reach/router"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "@radbse/auth", "moment", "reselect", "@radbse/hooks", "@reach/router"], factory);
	else if(typeof exports === 'object')
		exports["@radbse/auth-identity"] = factory(require("react"), require("@radbse/auth"), require("moment"), require("reselect"), require("@radbse/hooks"), require("@reach/router"));
	else
		root["@radbse/auth-identity"] = factory(root["React"], root["@radbse/auth"], root["moment"], root["reselect"], root["@radbse/hooks"], root["@reach/router"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__) {
return 