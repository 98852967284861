import React, { useContext, useMemo, useReducer, createContext } from 'react'
import moment from 'moment'
import { Redirect } from '@reach/router'
import { createSelector } from 'reselect'
import { useApi as useApiInternal, apiPost, apiGet, apiPut, apiDelete } from '@radbse/auth'
import { bindActionCreators } from '@radbse/hooks'
export { ApiError, jsonHeaders, apiPost, apiGet, apiPut, apiDelete } from '@radbse/auth'

export const jwtDecode = token => {
    const [encHeader, encPayload, encSignature] = token.split('.')

    return {
        header: JSON.parse(atob(encHeader)),
        payload: JSON.parse(atob(encPayload)),
        signature: encSignature,
    }
}

export const sinceEpoch = seconds => {
    const date = new Date(0)
    date.setUTCSeconds(seconds)
    return date
}

const stateSessionSelector = state => state.session

const jwtSelector = createSelector([stateSessionSelector], session => {
    if (!session.token) return {}
    return jwtDecode(session.token)
})

const refreshJwtSelector = createSelector([stateSessionSelector], session => {
    if (!session.refresh) return {}
    return jwtDecode(session.refresh)
})

const sessionPolicySelector = createSelector([jwtSelector], jwt => {
    if (jwt.payload && jwt.payload.pol) {
        if (!Array.isArray(jwt.payload.pol)) return [jwt.payload.pol]
        return jwt.payload.pol
    }
    return []
})

const sessionUserSelector = createSelector([jwtSelector], jwt => {
    const user = { ...jwt.payload }
    if (user.pol) delete user.pol
    if (user.sub) delete user.sub
    if (user.iss) delete user.iss
    if (user.aud) delete user.aud
    if (user.exp) delete user.exp
    if (user.nbf) delete user.nbf
    if (user.iat) delete user.iat
    if (user.jti) delete user.jti

    return user
})

export const sessionSelector = createSelector(
    [stateSessionSelector, jwtSelector, refreshJwtSelector, sessionPolicySelector, sessionUserSelector],
    (session, jwt, refresh_jwt, policies, user) => {
        const authenticated = !!session.token
        const token_iat = jwt.payload ? sinceEpoch(jwt.payload.iat) : new Date()
        const token_expires = jwt.payload ? sinceEpoch(jwt.payload.exp) : new Date()
        const refresh_expires = refresh_jwt.payload ? sinceEpoch(refresh_jwt.payload.exp) : new Date()
        const server_offset = moment(token_iat).diff(session.tokenDate)
        var roles = []
        if (Array.isArray(user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'])) {
            roles = user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
        } else {
            if (user.hasOwnProperty('http://schemas.microsoft.com/ws/2008/06/identity/claims/role')) {
                roles = [user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']]
            }
        }
        var permissions = []
        if (Array.isArray(user['http://localhost:5000/claims/permission'])) {
            permissions = user['http://localhost:5000/claims/permission']
        } else {
            if (user.hasOwnProperty('http://localhost:5000/claims/permission')) {
                permissions = [user['http://localhost:5000/claims/permission']]
            }
        }
        const hasPolicy = search => !!policies.find(policy => policy.toLowerCase() === search.toLowerCase())
        const inRole = search => !!roles.find(role => role.toLowerCase() === search.toLowerCase())
        const hasPermission = search => !!permissions.find(claim => claim.toLowerCase() === search.toLowerCase())

        return {
            ...session,
            jwt,
            refresh_jwt,
            server_offset,
            token_expires,
            refresh_expires,
            policies,
            hasPolicy,
            inRole,
            hasPermission,
            authenticated,
            user,
        }
    }
)

export const SessionContext = createContext({})

const emptyState = {
    token: null,
    refresh: null,
    rememberMe: null,
    expires: null,
    requiresLogin: false,
}

export const AuthContext = createContext()

export const getLocalAuthState = () => {
    const state = JSON.parse(localStorage.getItem('auth'))
    if (!state) return null
    const session = sessionSelector({ session: state })
    const serverNow = moment().add(state.server_offset, 'milliseconds')
    if (moment(session.refresh_expires) < serverNow) return null
    return state
}

export const getInitialAuthState = () =>
    useMemo(() => getLocalAuthState() || emptyState, [localStorage.getItem('auth')])

const AUTH__LOGIN_REQUEST = 'AUTH__LOGIN_REQUEST'
const AUTH__LOGIN_SUCCESS = 'AUTH__LOGIN_SUCCESS'
const AUTH__LOGIN_FAILURE = 'AUTH__LOGIN_FAILURE'

const AUTH__SIGNUP_REQUEST = 'AUTH__SIGNUP_REQUEST'
const AUTH__SIGNUP_SUCCESS = 'AUTH__SIGNUP_SUCCESS'
const AUTH__SIGNUP_FAILURE = 'AUTH__SIGNUP_FAILURE'

const AUTH__REMEMBER_ME_LOGIN_REQUEST = 'AUTH__REMEMBER_ME_LOGIN_REQUEST'
const AUTH__REMEMBER_ME_LOGIN_SUCCESS = 'AUTH__REMEMBER_ME_LOGIN_SUCCESS'
const AUTH__REMEMBER_ME_LOGIN_FAILURE = 'AUTH__REMEMBER_ME_LOGIN_FAILURE'

const AUTH__REFRESH_TOKEN_REQUEST = 'AUTH__REFRESH_TOKEN_REQUEST'
const AUTH__REFRESH_TOKEN_SUCCESS = 'AUTH__REFRESH_TOKEN_SUCCESS'
const AUTH__REFRESH_TOKEN_FAILURE = 'AUTH__REFRESH_TOKEN_FAILURE'

export const AUTH__LOGOUT_REQUEST = 'AUTH__LOGOUT_REQUEST'
export const AUTH__LOGOUT_SUCCESS = 'AUTH__LOGOUT_SUCCESS'
export const AUTH__LOGOUT_FAILURE = 'AUTH__LOGOUT_FAILURE'

const dontSentJwtBearer = false

export const authActionCreators = {
    api: {
        logout: apiPost('/api/account/logout', [AUTH__LOGOUT_REQUEST, AUTH__LOGOUT_SUCCESS, AUTH__LOGOUT_FAILURE]),
        login: apiPost(
            '/api/account/login',
            [AUTH__LOGIN_REQUEST, AUTH__LOGIN_SUCCESS, AUTH__LOGIN_FAILURE],
            dontSentJwtBearer
        ),
        signup: apiPost(
            '/api/account/register',
            [AUTH__SIGNUP_REQUEST, AUTH__SIGNUP_SUCCESS, AUTH__SIGNUP_FAILURE],
            dontSentJwtBearer
        ),
        refreshToken: apiPost('/api/jwt/refresh', [
            AUTH__REFRESH_TOKEN_REQUEST,
            AUTH__REFRESH_TOKEN_SUCCESS,
            AUTH__REFRESH_TOKEN_FAILURE,
        ]),
    },
}

export const authReducer = (state, action) => {
    switch (action.type) {
        case AUTH__LOGIN_SUCCESS: {
            if (action.response.rememberMe.length > 0) {
                localStorage.setItem('rememberMe', action.response.rememberMe)
            }
            const auth = {
                token: action.response.token,
                refresh: action.response.refresh,
                requiresLogin: false,
                tokenDate: moment(),
            }
            localStorage.setItem('auth', JSON.stringify(auth))
            return { ...state, ...action.response }
        }
        case AUTH__REFRESH_TOKEN_SUCCESS: {
            const auth = {
                token: action.response.token,
                refresh: action.response.refresh,
                requiresLogin: false,
                tokenDate: moment(),
            }
            localStorage.setItem('auth', JSON.stringify(auth))
            return auth
        }
        case AUTH__REMEMBER_ME_LOGIN_SUCCESS: {
            const auth = {
                token: action.response.token,
                refresh: action.response.refresh,
                requiresLogin: false,
                tokenDate: moment(),
            }
            localStorage.setItem('auth', JSON.stringify(auth))
            return auth
        }
        case AUTH__SIGNUP_SUCCESS: {
            const auth = {
                token: action.response.token,
                refresh: action.response.refresh,
                requiresLogin: false,
                tokenDate: moment(),
            }
            localStorage.setItem('auth', JSON.stringify(auth))
            return { ...state, ...action.response }
        }
        case AUTH__LOGOUT_FAILURE:
        case AUTH__LOGOUT_SUCCESS: {
            localStorage.removeItem('auth')
            return { ...emptyState }
        }
        default:
            return { ...state }
    }
}

export const useApi = (action, dispatch, authContext = AuthContext, callOnRefresh) => {
    const { state, dispatch: authDispatch } = useContext(authContext)
    const { refresh, token } = state
    const [loading, apiFn] = useApiInternal(action, dispatch, authContext)

    const refreshApiFn = useMemo(() => {
        const { sendAuthBearer } = action
        if (!sendAuthBearer) return apiFn

        return async (data = {}) => {
            const tokenState = sessionSelector({ session: state })

            if (!tokenState.authenticated) throw new Error('User must be logged in')

            const serverNow = moment().add(tokenState.server_offset, 'milliseconds')
            if (moment(tokenState.refresh_expires) < serverNow) throw new Error('User must be logged in')

            if (moment(tokenState.token_expires) < serverNow) {
                const { url, types, method, headers, noCache } = authActionCreators.api.refreshToken
                const [requestType, successType, failureType] = types
                let config = {
                    method,
                    ...headers,
                }
                const authHeader = { AUTHORIZATION: `Bearer ${refresh}` }
                config = { ...config, headers: { ...config.headers, ...authHeader } }

                if (noCache) {
                    config = {
                        ...config,
                        cache: 'no-cache',
                        headers: {
                            ...config.headers,
                            ...{ pragma: 'no-cache', 'cache-control': 'no-cache' },
                        },
                    }
                }

                if (authDispatch) authDispatch({ type: requestType })

                try {
                    const fetchResponse = await fetch(url, config)
                    const response = await fetchResponse.json()
                    if (authDispatch) authDispatch({ response: response.result, type: successType })
                    if (callOnRefresh) {
                        return await apiFn(data, response.result.token)
                    }
                } catch (error) {
                    if (authDispatch) authDispatch({ error, type: failureType })
                }
            } else {
                return await apiFn(data)
            }
        }
    }, [action, dispatch, token])

    return [loading, refreshApiFn]
}

export const AuthProvider = ({
    initialState,
    children,
    reducer = authReducer,
    actionCreators = authActionCreators,
}) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const providerValue = {
        state,
        actions: bindActionCreators(actionCreators, dispatch),
        dispatch,
    }
    return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)

export const useAuthApi = () => {
    const { actions, dispatch } = useAuth()
    return [actions, action => useApi(action, dispatch, AuthContext)]
}

export const SessionProvider = ({ children }) => {
    const { state } = useAuth()
    const sessionState = useMemo(() => sessionSelector({ session: state }), [sessionSelector, state])
    return <SessionContext.Provider value={sessionState}>{children}</SessionContext.Provider>
}

export const useSession = () => useContext(SessionContext)

export const ApiProvider = ({ initialState, children, reducer, actionCreators, context: Context }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const providerValue = {
        state,
        actions: bindActionCreators(actionCreators, dispatch),
        dispatch,
    }
    return <Context.Provider value={providerValue}>{children}</Context.Provider>
}

export const useCustomApi = context => {
    const { actions, dispatch, state } = useContext(context)
    return [actions, (action, callOnRefresh = true) => useApi(action, dispatch, AuthContext, callOnRefresh), state]
}

const RedirectBack = ({ to, location }) => {
    localStorage.setItem('returnUrl', location.pathname)
    return <Redirect noThrow to={to} />
}

const WithAuth = ({ redirect: redirectProp, location, roles, permissions, children }) => {
    const { state } = useContext(AuthContext)
    const session = sessionSelector({ session: state })
    const redirect = redirectProp || '/login'
    if (!session.authenticated) return <RedirectBack to={redirect} location={location} />
    if (permissions && !permissions.some(permission => session.hasPermission(permission)))
        return <RedirectBack to={redirect} location={location} />
    if (roles && !roles.some(role => session.inRole(role))) return <RedirectBack to={redirect} location={location} />
    return <>{children}</>
}

export const withAuth = Wrapped => props => {
    const { redirect, location, roles, permissions, ...passThrough } = props
    return (
        <WithAuth redirect={redirect} location={location} roles={roles} permissions={permissions}>
            <Wrapped {...passThrough} />
        </WithAuth>
    )
}

export const withProvider = (Wrapped, Provider) => props => {
    return (
        <Provider>
            <Wrapped {...props} />
        </Provider>
    )
}
